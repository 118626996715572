import Auth from '@/components/Auth'
import { SessionProvider } from 'next-auth/react'
import { ProtectedAppProps } from 'types/auth'
import '../styles/globals.css'

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: ProtectedAppProps) {
  return (
    <SessionProvider session={session}>
      {Component.auth ? (
        <Auth>
          <Component {...pageProps} />
        </Auth>
      ) : (
        <Component {...pageProps} />
      )}
    </SessionProvider>
  )
}
